import React, { useState, useEffect } from 'react'
import { Box, Hidden, Link } from '@material-ui/core'
import { SWAPLogo, Typography } from '@yosgo/swap-ui'
import AuthModal from './AuthModal'
import BizAuthModal from './BizAuthModal'
import { ParseAccountToken } from '../../utils/HandleToken'
import { PathOr } from '../../utils/FunctionsBox'
import { FreshChatOpen } from './FreshChat'

interface LoginModalProps {
  open: boolean
  onClose: () => void
}
const LoginModal = ({ open, onClose }: LoginModalProps) => {
  return <AuthModal open={open} onClose={onClose} type="login" />
}
interface RegisterModalProps {
  open: boolean
  onClose: () => void
}
const RegisterModal = ({ open, onClose }: RegisterModalProps) => {
  return <AuthModal open={open} onClose={onClose} type="register" />
}

/**
 * FooterProps
 *
 * @type 非必填，預設 soho
 * @showAuth 非必填，預設 show
 */
interface FooterProps {
  type?: 'biz' | 'soho'
  showAuth?: boolean
}

const Footer = (props: FooterProps) => {
  const [userType, setUserType]: ['business' | 'member' | undefined, any] =
    useState(undefined)
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false)
  const [bizAuthModal, setBizAuthModal] = useState(false)
  const [bizAuthModalType, setBizAuthModalType] = useState('login')
  const dt = new Date()
  const year = dt.getFullYear()

  const { showAuth, type } = props

  useEffect(() => {
    /**判斷使用者類型 */
    if (
      window.location.href.includes('/business' || '/biz') &&
      !window.location.href.includes('/partner-invite')
    ) {
      setUserType(PathOr(undefined, ['type'], ParseAccountToken('biz')))
    } else {
      setUserType(PathOr(undefined, ['type'], ParseAccountToken()))
    }
  }, [])

  const footerConfig: any =
    type !== 'biz'
      ? [
          /**SOHO */
          {
            title: '關於產品',
            lists: [
              { text: '常見問答', url: '/faqs' },
              { text: '收費方案', url: '/pricing' },
            ],
          },
          {
            title: '使用條款',
            lists: [
              { text: '網站使用條款', url: '/terms' },
              { text: '隱私權政策', url: '/privacy-policy' },
            ],
          },
          {
            title: '使用產品',
            lists: [
              {
                text: '註冊新帳號',
                onClick: () => {
                  setIsOpenRegisterModal(true)
                },
                hidden: showAuth === false,
              },
              {
                text: '登入帳號',
                onClick: () => {
                  setIsOpenLoginModal(true)
                },
                hidden: showAuth === false,
              },
              {
                text: '請款單試用',
                url: '/tools/payment?amt=100000',
              },
              {
                text: 'SWAP 企業版',
                url: '/biz',
              },
            ],
          },
          {
            title: '尋求協助',
            lists: [
              { text: '部落格', url: 'https://blog.swap.work/' },
              { text: '聯絡我們 swap@yosgo.com', url: 'mailto:swap@yosgo.com' },
              {
                text: '聯繫線上客服',
                onClick: FreshChatOpen,
              },
            ],
          },
        ]
      : [
          /**Biz */
          {
            title: '關於產品',
            lists: [
              { text: '常見問答', url: '/biz/faqs' },
              { text: '價格方案', url: '/biz/pricing' },
            ],
          },
          {
            title: '使用條款',
            lists: [
              { text: '網站使用條款', url: '/terms' },
              { text: '隱私權政策', url: '/privacy-policy' },
            ],
          },
          {
            title: '使用產品',
            hidden: userType === 'business' || showAuth === false,
            lists: [
              {
                text: '註冊新帳號',
                onClick: () => {
                  setBizAuthModalType('register')
                  setBizAuthModal(true)
                },
                hidden: userType === 'business' || showAuth === false,
              },
              {
                text: '登入帳號',
                onClick: () => {
                  setBizAuthModalType('login')
                  setBizAuthModal(true)
                },
                hidden: userType === 'business' || showAuth === false,
              },
              {
                text: 'SWAP 個人版',
                url: '/',
              },
            ],
          },
          {
            title: '尋求協助',
            lists: [
              { text: '部落格', url: 'https://blog.swap.work/' },
              { text: '聯絡我們 swap@yosgo.com', url: 'mailto:swap@yosgo.com' },
              {
                text: '聯繫線上客服',
                onClick: FreshChatOpen,
              },
            ],
          },
        ]

  return (
    <div>
      {/* 登入或註冊 Modal */}
      <LoginModal
        open={isOpenLoginModal}
        onClose={() => setIsOpenLoginModal(false)}
      />
      <RegisterModal
        open={isOpenRegisterModal}
        onClose={() => setIsOpenRegisterModal(false)}
      />
      {/**Biz 登入或註冊 Modal */}
      <BizAuthModal
        open={bizAuthModal}
        onClose={() => setBizAuthModal(false)}
        type={bizAuthModalType}
      />
      <div
        style={{
          position: 'relative',
          zIndex: 1,
          boxShadow: '0px -1px 0px #ECECEC',
          backgroundColor: 'white',
        }}
      >
        {/**桌面版 */}
        <Hidden smDown>
          <div
            style={{
              width: '100%',
              height: 270,
              display: 'flex',
              justifyContent: 'center',
              padding: '40px 24px',
            }}
          >
            <Box
              style={{
                width: '100%',
                maxWidth: 1200,
                height: 157,
                display: 'grid',
                gridGap: 12,
                gridTemplateColumns:
                  type === 'biz' && userType === 'business'
                    ? '1fr 1fr 1fr'
                    : '1fr 1fr 1fr 1fr',
                gridTemplateRows: '1fr 1fr 1fr 1fr 1fr',
                position: 'relative',
              }}
            >
              {footerConfig.map((item: any, index: number) => (
                <Box
                  display={item.hidden ? 'none' : 'block'}
                  key={`row1_${item.title}_${index}`}
                >
                  <Typography variant="caption1" style={{ marginBottom: 4 }}>
                    {item.title}
                  </Typography>
                  <Box marginTop="16px">
                    {item.lists.map((list: any, listIndex: number) => (
                      <Box
                        display={list.hidden ? 'none' : 'block'}
                        marginTop="12px"
                        onClick={() => {
                          if (list.url) {
                            window.location.href = list.url
                          } else {
                            list?.onClick()
                          }
                        }}
                        key={`list_${list.text}_${listIndex}`}
                      >
                        <Typography
                          variant="body2_loose"
                          color="black700"
                          style={{ cursor: 'pointer' }}
                        >
                          <Link color="inherit">{list.text}</Link>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
              {/* copyright */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  left: 0,
                  bottom: -50,
                }}
              >
                <SWAPLogo size="small" />
                <Typography
                  variant="caption2"
                  color="black700"
                  style={{ marginLeft: 16, marginTop: 3.1 }}
                >
                  &copy; {year} 優市股份有限公司，統一編號 52601428，SWAP 為
                  <a href="https://yosgo.com">優市</a>
                  旗下品牌。著作權所有，並保留一切權利。
                </Typography>
              </div>
            </Box>
          </div>
        </Hidden>
        {/**手機版 */}
        <Hidden mdUp>
          <Box
            width="100%"
            padding="40px 24px"
            display="flex"
            flexDirection="column"
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              {footerConfig.map((item: any, index: number) => (
                <Box
                  display={item.hidden ? 'none' : 'flex'}
                  flexDirection="column"
                  width={155.5}
                  key={`mobile_${index}_${item.title}`}
                  marginBottom="40px"
                >
                  <Typography variant="caption1">{item.title}</Typography>
                  {item.lists.map((list: any, listIndex: number) => (
                    <Box
                      key={`mobile_list_${list.text}_${listIndex}`}
                      display={list.hidden ? 'none' : 'block'}
                      onClick={() => {
                        if (list.url) {
                          window.location.href = list.url
                        } else {
                          list?.onClick()
                        }
                      }}
                    >
                      <Typography
                        variant="body2_loose"
                        color="black700"
                        style={{ marginTop: 16, cursor: 'pointer' }}
                      >
                        <Link color="inherit">{list.text}</Link>
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              style={{ paddingRight: '40px' }}
            >
              <SWAPLogo size="small" />
              <Typography
                variant="caption2"
                style={{ marginLeft: 16, opacity: 0.56 }}
              >
                &copy; {year} 優市股份有限公司，統一編號 52601428，SWAP 為
                <a href="https://yosgo.com" target="_blank">
                  優市
                </a>
                旗下品牌。著作權所有，並保留一切權利。
              </Typography>
            </Box>
          </Box>
        </Hidden>
      </div>
    </div>
  )
}

export default Footer

import React, { useEffect, useRef, useState } from 'react'

// Slide 的客製化箭頭
interface SlideArrowProps {
  className?: string,
  onClick?: () => void
}
function SlideNextArrow(props: SlideArrowProps) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9,
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <div style={{
        width: 92,
        height: 86,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        {circle_button_default}
      </div>
      <div style={{
        width: 24,
        height: 24,
        position: 'absolute',
        top: '50%',
        left: 'calc(50% - 2px)',
        transform: 'translate(-50%, -50%)',
        zIndex: 99
      }}>
        {arrow_right_icon}
      </div>
    </div>
  );
}
function SlidePrevArrow(props: SlideArrowProps) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      style={{
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9,
        cursor: 'pointer'
    }}
      onClick={onClick}
    >
      <div style={{
        width: 92,
        height: 86,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        {circle_button_default}
      </div>
      <div style={{
        width: 24,
        height: 24,
        position: 'absolute',
        top: '50%',
        left: 'calc(50% - 2px)',
        transform: 'translate(-50%, -50%)',
        zIndex: 99
      }}>
        {arrow_left_icon}
      </div>
    </div>
  );
}

interface SliderProps {
  children: any[];
  slidesToShow?: number;
}
const Slider = ({ children, slidesToShow = 1 }: SliderProps) => {
  const refContainer: any = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [page, setPage] = useState(0)

  const updateSize = () => {
    if (refContainer.current && refContainer.current.clientWidth) {
      setContainerWidth(refContainer.current.clientWidth)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
  }, [])

  return (
    <div
      ref={refContainer}
      style={{
        width: '100%',
        position: 'relative'
      }}
    >
      <div style={{
        width: `${containerWidth * children.length / slidesToShow}px`,
        transform: `translateX(-${containerWidth * page}px)`,
        transition: 'transform 300ms ease-in',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }}>
        {children.map((item: any, index: number) => (
          <div style={{
            minWidth: `${containerWidth / slidesToShow - (12 * (slidesToShow - 1))}px`,
            width: '100%',
            marginRight: (index + 1) % slidesToShow !== 0 ? 24 : 0,
            opacity: index >= (page * slidesToShow) && index < ((page + 1) * slidesToShow) ? 1 : 0,
            transition: 'opacity 300ms ease-in',
          }}>
            {item}
          </div>
        ))}
      </div>
      {page > 0 && <SlidePrevArrow onClick={() => setPage(page - 1)} />}
      {(page + 1) * slidesToShow < children.length && <SlideNextArrow onClick={() => setPage(page + 1)} />}
    </div>
  )
}

const circle_button_default = (
  <svg xmlns="http://www.w3.org/2000/svg" width="92" height="86" viewBox="0 0 92 86" fill="none">
    <g filter="url(#filter0_d_199_2423)">
      <circle cx="44" cy="42" r="24" fill="white"/>
    </g>
    <defs>
      <filter id="filter0_d_199_2423" x="-4" y="0" width="96" height="96" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="6"/>
        <feGaussianBlur stdDeviation="12"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_199_2423"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_199_2423" result="shape"/>
      </filter>
    </defs>
  </svg>
)

const arrow_left_icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M19.9999 11.0001V13.0001H7.99991L13.4999 18.5001L12.0799 19.9201L4.15991 12.0001L12.0799 4.08008L13.4999 5.50008L7.99991 11.0001H19.9999Z" fill="black"/>
  </svg>
)
const arrow_right_icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M4 11.0001V13.0001H16L10.5 18.5001L11.92 19.9201L19.84 12.0001L11.92 4.08008L10.5 5.50008L16 11.0001H4Z" fill="black"/>
  </svg>
)

export default Slider
